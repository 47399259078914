<template>
    <div class="page">
        <div class="content">
            <div style="width: 100%;height: 80vh;background-color: #fff;padding: 20px;">
                <Form :model="formItem" :label-width="140" style="width: 500px;margin-top: 20px">
                    <FormItem label="原始密码：">
                        <Input v-model="formItem.old_pwd" type="password" placeholder="请输入原始密码"></Input>
                    </FormItem>
                    <FormItem label="新密码：">
                        <Input v-model="formItem.new_pwd" type="password" placeholder="请输入新密码"></Input>
                    </FormItem>
                    <FormItem label="再次输入：">
                        <Input v-model="update_password" type="password" placeholder="请再次输入新密码"></Input>
                    </FormItem>
                    <FormItem class="foot">
                        <Button type="primary" @click="addBtn">保存</Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import {loginModify} from '@/api/index'
export default {
  name: "password",
  data() {
    return {
      update_password:'',
      formItem: {
        old_pwd:'',
        new_pwd:''
      },
    }
  },
  methods: {
    addBtn(){
      if (this.new_pwd == this.formItem.update_password) {
        loginModify(this.formItem).then(res=>{
          this.$Message.success(res.msg)
          this.formItem.old_pwd = ''
          this.formItem.new_pwd = ''
          this.update_password = ''
          this.$router.push({path: '/'})
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      }else{
        this.$Message.error('两次密码输入不一致')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    position: relative;
    .foot{
            position: absolute;
            width: 95%;
            bottom: 0;
            padding: 10px 0;
            border-top: 1px solid #ccc;
            margin: 10px 0;
            text-align: center;
        }
}
</style>